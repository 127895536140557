import React, { Component } from 'react';

class Header extends Component {
   constructor(props){
      super(props)
      this.state = {
         imageName: 'image',
         imageCount: 1,
         nextIconCount: 0,
         prevIconCount: 0
      }
   }

   getRandomNum = () => {
      let rand = Math.floor(Math.random() * 19) + 1;
      return 'image' + rand
   }

   handleLangChange = (e) => {
      this.props.parentLangChange(e.target.getAttribute('id'))
   }

   nextImage = (e) => {
      e.preventDefault()
      const objectImageCount = Object.keys(this.props.data.headerimage).length
      if (this.state.imageCount === objectImageCount) {
         this.setState(state => ({ imageCount: objectImageCount - (state.imageCount - 1) }))
         return
      }
      if (this.state.nextIconCount === 4) {
         this.setState(state => ({ imageCount: state.imageCount + 1, prevIconCount: state.nextIconCount, nextIconCount: 0 }))
         return
      }
      this.setState(state => ({ imageCount: state.imageCount + 1, prevIconCount: state.nextIconCount, nextIconCount: state.nextIconCount + 1 }))
   }
   previousImage = (e) => {
      e.preventDefault()
      const objectImageCount = Object.keys(this.props.data.headerimage).length
      if (this.state.imageCount === (objectImageCount - (objectImageCount - 1) )) {
         this.setState(state => ({ imageCount: objectImageCount }))
         return
      }
      if (this.state.nextIconCount === 0) {
         this.setState(state => ({ imageCount: state.imageCount - 1, prevIconCount: state.nextIconCount, nextIconCount: 4 }))
         return
      }
      this.setState(state => ({ imageCount: state.imageCount - 1, prevIconCount: state.nextIconCount, nextIconCount: state.nextIconCount - 1 }))
   }

   render() {
      if(this.props.menus){
         var { home, about, resume, tech, work, testimonial, contact } = this.props.menus
      }
      if (this.props.data) {
         var name = this.props.data.name;
         var occupation = this.props.data.occupation;
         var description = this.props.data.description;
         // var city = this.props.data.address.city;
         var language = this.props.language
         var emoji = this.props.emoji
         var networks = this.props.data.social.map(function (network) {
            return <li key={network.name}><a href={network.url} target="_blank" rel="noopener noreferrer"><i className={network.className} id={network.id}></i></a></li>
         })

         var image_name = this.state.imageName + this.state.imageCount
         var headerimage = this.props.data.headerimage[image_name];
         var homeIdTag = document.querySelector('#home')
         homeIdTag.setAttribute("style", `background: #161415 url(${headerimage}) no-repeat top center !important`)

         // Set initial Social media color
         const soc = document.querySelector('.socialmedia')
         const { prevIconCount, nextIconCount } = this.state
         if (this.state.nextIconCount >= 0) {
            // console.log('Prev', prevIconCount)
            // console.log('Next', nextIconCount)
            if (prevIconCount !== nextIconCount) {
               soc.children[prevIconCount].children[0].children[0].style.color = "#FFF"
            }
            setTimeout(() => soc.children[nextIconCount].children[0].children[0].style.color = "#F06000", 100);
         }
      }

      return (
         <header id="home" className="img-fluid lazy" style={{ background: `#161415 url(${headerimage}) no-repeat top center` }}>
            <nav id="nav-wrap">

               <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
               <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

               <ul id="nav" className="nav">
                  <li className="current"><a className="smoothscroll" href={home?.link + ""}>{home?.name}</a></li>
                  <li><a className="smoothscroll" href={about?.link + ""}>{about?.name}</a></li>
                  <li><a className="smoothscroll" href={resume?.link + ""}>{resume?.name}</a></li>
                  <li><a className="smoothscroll" href={tech?.link + ""}>{tech?.name}</a></li>
                  <li><a className="smoothscroll" href={work?.link + ""}>{work?.name}</a></li>
                  <li><a className="smoothscroll" href={testimonial?.link + ""}>{testimonial?.name}</a></li>
                  <li><a className="smoothscroll" href={contact?.link + ""}>{contact?.name}</a></li>
                  <li>
                     <div className="container">
                        <div className="dropdown">
                           <div className="select">
                              <span id="lang-flug">{emoji}</span>
                              <span id="lang-label">{language}</span>
                              <i className="fa fa-chevron-left"></i>
                           </div>
                           <ul className="dropdown-menu">
                              <li id="en" onClick={this.handleLangChange} >English</li>
                              <li id="pt" onClick={this.handleLangChange} >Português</li>
                           </ul>
                        </div>
                     </div>
                  </li>
               </ul>
            </nav>

            <div className="row banner">
               <div className="banner-text">
                  <h1 className="responsive-headline">{name}</h1>
                  <h3><span>{occupation}</span></h3>
                  <h3>{description}</h3>
                  <hr />
                  <div className="social" >
                     <div className="socialmedia">{networks}</div>
                     <br />
                     <span role="img" aria-label="Countries">🇦🇴 &nbsp; 🇿🇦 &nbsp; 🇺🇸 &nbsp; 🇬🇧 &nbsp; 🇨🇦</span>
                  </div>
               </div>
            </div>

            <p className="scrolldown">
               <a className="smoothscroll" href="#about"><i className="icon-down-circle"></i></a>
            </p>
            <p className="slide-right">
               <a className="" onClick={this.nextImage}><i className="icon-right-circle"></i></a>
            </p>
            <p className="slide-left">
               <a className="" onClick={this.previousImage}><i className="icon-left-circle"></i></a>
            </p>
         </header>
      );
   }
}

export default Header;
