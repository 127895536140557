import React, { Component } from 'react'

export class DevStack extends Component {
    render() {
        if(this.props.techs){
            var { title, subtitle } = this.props.techs
        }
        if (this.props.data) {
            var techs = this.props.data.map(tech => {
                return (
                    <div key={tech.name} className="columns feature-item">
                        <img className="skill" alt={tech.name} src={`images/tech/` + tech.image} />
                        <h5>{tech.name}</h5>
                        {/* <p>{tech.info}</p> */}
                    </div>)
            })
        }

        return (
            <section id="devstack" style={{ backgroundColor: "#191919" }}>
                <div className="row skill" >
                    <div className="three columns header-col" >
                        <h1><span style={{ fontSize: "20px", borderBottom: "2px #11ABB0 solid", color: "#11ABB0" }}>{title}</span></h1>
                    </div>
                    <div>
                        <div className="nine columns main-col" style={{ marginTop: "-38px", paddingBottom: "18px", paddingTop: "18px" }}>
                            <p className="lead center">{subtitle}</p>
                        </div>
                        <ul className="bgrid-quarters s-bgrid-thirds cf">
                            {techs}
                        </ul>
                    </div>
                </div>
            </section>
        )
    }
}

export default DevStack
