import React, { Component } from 'react';

class Resume extends Component {
  render() {
    if(this.props.resume){
      var headers = this.props.resume
    }

    if (this.props.data) {
      var skillmessage = this.props.data.skillmessage;
      var education = this.props.data.education.map(function (education) {
        return <div key={education.school}>
          <h3>{education.school}</h3>
          <span className="info">{education.degree} <span>&bull;</span> <em className="date">{education.graduated}</em></span>
          <br />
          <span>{education.description} <span style={{fontSize:"22px"}} role="img" aria-labelledby="emoji">👏🏽</span> </span>
        </div>
      })
      var work = this.props.data.work.map(function (work) {
        return <div key={work.company} className="workDescript">
          <h3>{work.company}</h3>
            <span className="info">{work.title}</span>
            <span>{work.description}</span>
            <br />
            <a href={work.link}><span>{work.link}</span></a>
          </div>
      })
      var skills = this.props.data.skills.map(function (skills) {
        var className = 'bar-expand ' + skills.name.toLowerCase();
        return <li key={skills.name}><span style={{ width: skills.level }} className={className}></span><em>{skills.name}</em></li>
      })
    }

    return (
      <section id="resume">
        <div className="row education">
          <div className="three columns header-col">
            <h1><span>{headers?.education}</span></h1>
          </div>
          <div className="nine columns main-col">
            <div className="row item">
              <div className="twelve columns">
                {education}
              </div>
            </div>
          </div>
        </div>
        <div className="row work">
          <div className="three columns header-col">
            <h1><span style={{ paddingBottom: "10px !important" }}>{headers?.experience.title}</span></h1>
            <h1><span>{headers?.experience.subtitle}</span></h1>
          </div>
          <div className="nine columns main-col">
            {work}
          </div>
        </div>

        <div className="row skill">
          <div className="three columns header-col">
            <h1><span>{headers?.skill}</span></h1>
          </div>
          <div className="nine columns main-col">
            <p> {skillmessage} </p>
            <div className="bars">
              <ul className="skills">
                {skills}
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Resume;
