import React, { Component } from 'react';

class About extends Component {
   getRandomNum = () => {
      let rand = Math.floor(Math.random() * 2) + 1;
      return 'image' + rand
   }
   render() {
      if(this.props.about){
         var { aboutTitle, contactDetails} = this.props.about
      }
      if (this.props.data) {
         var fullname = this.props.data.fullname;
         var profilepic = "images/" + this.props.data[this.getRandomNum()];
         var bio = this.props.data.bio;
         var street = this.props.data.address.street;
         var city = this.props.data.address.city;
         var state = this.props.data.address.state;
         var zip = this.props.data.address.zip;
         var phone = this.props.data.phone;
         var email = this.props.data.email;
         var resumeEN = this.props.data.resumeLinkEN;
         var resumePT= this.props.data.resumeLinkPT;
      }

      return (
         <section id="about">
            <div className="row">
               <div className="three columns text-center">
                  <img className="prof-pic" src={profilepic} alt="BlackBeard Profile Pic" />
               </div>
               <div className="nine columns main-col">

                  <h2>{aboutTitle}</h2>
                  <p>{bio}</p>
                  <div className="row">
                     <div className="columns contact-details">
                        <h2>{contactDetails} </h2>
                        <p className="address">
                           <span>{fullname}</span><br />
                           <span>{street}<br />
                              {city} {state} {zip}
                           </span><br />
                           <span>{phone}</span><br />
                           <span style={{ color: "#11abb0" }}>{email}</span>
                        </p>
                     </div>
                     <div className="columns download">
                        <p>
                           <a href={resumeEN} target="_blank" rel="noopener noreferrer" className="button"><i className="fa fa-download"></i>CV - English</a>
                           <br />
                           <a href={resumePT} target="_blank" rel="noopener noreferrer" className="button"><i className="fa fa-download"></i>CV - Português</a>
                        </p>
                     </div>
                  </div>
               </div>
            </div>

         </section>
      );
   }
}

export default About;
