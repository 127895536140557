import React, { Component } from 'react';
// import $ from 'jquery';
import Header from './Components/Header';
import Footer from './Components/Footer';
import About from './Components/About';
import Resume from './Components/Resume';
import Contact from './Components/Contact';
import Testimonials from './Components/Testimonials';
import Portfolio from './Components/Portfolio';
import DevStack from './Components/DevStack';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foo: 'bar',
      resumeData: {},
      pageData: {},
      language: 'en',
      emoji: '🇬🇧',
      defaultLanguage: [
        { code: 'en', flug: '🇬🇧' },
        { code: 'pt', flug: '🇦🇴' }
      ],
      userLocation: {},
      options: {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      },
    };
  }

  componentDidMount() {
    let language = localStorage.getItem('currentLocation')
    let emoji = localStorage.getItem('currentEmoji')
    // Set overflow Off
    document.querySelector('body').setAttribute("style", `overflow: hidden !important;`)

    if(language && emoji){
      this.setState({ language, emoji }, () => {
        this.fetchPageDetails(this.state.language)
        this.getResumeDataOnLanguage(this.state.language)
        this.setLanguageEmoji()
        this.removeSpinner()
      })
    }else{
      this.fetchUserLocation();
      // this.checkIfContains('def');
    }
  }

  // fetchUserLocation() {
  //   fetch('https://api.ipdata.co/?api-key=2f96c0131ef3177bbda3ff11da71f816d7d576bc18e4bbbc613aa2b7')
  //     .then(res => res.json())
  //     .then(data => {
  //       console.log(data)
  //       if (data && data.ip && data.emoji_flag && data.languages && data.languages.length > 0) {
  //         this.setState({ userLocation: data })
  //         let countryCode = false
  //         data.languages.forEach(item => {
  //           if (item && item.name && item.name.includes("Portuguese")) {
  //             countryCode = "pt"
  //           }
  //           if (item && item.name && item.name.includes("English")) {
  //             countryCode = "en"
  //           }
  //         })

  //         // Set Country-Flug
  //         if (/\p{Emoji}/u.test(data.emoji_flag)) {
  //           this.setState({ emoji: data.emoji_flag })
  //         }

  //         // Set Language
  //         if (countryCode && countryCode.length > 0) {
  //           console.log("From API default")
  //           this.setState({ language: countryCode })
  //           this.fetchPageDetails(countryCode)
  //           this.getResumeDataOnLanguage(countryCode)
  //           // Save lang and emoji in LocalStorage 
  //           this.setLanguageEmoji()
  //           this.removeSpinner();
  //         } else {
  //           // Default
  //           console.log("From UPPPP default")
  //           this.checkIfContains('def')
  //           this.setLanguageEmoji()
  //           this.removeSpinner();
  //         }
  //         console.log("Inside here")
  //       } else {
  //         try {
  //           console.log("From default")
  //           // Get the location-language from the navigator window.navigator.language || 
  //           const language = window.navigator.language || window.navigator.systemLanguage || window.navigator.userLanguage || window.navigator.browserLanguage || window.navigator.languages
  //           if (Array.isArray(language) && language.length > 0) {
  //             this.checkIfContains(language[2])
  //           } else if (typeof language === 'string') {
  //             this.checkIfContains('language')
  //           } else {
  //             this.checkIfContains('def')
  //           }
  //         } catch (err) {
  //           console.log(err)
  //         }
  //       }
  //     })
  //     .catch(err => console.log(err))
  // }

  fetchUserLocation() {
    fetch('https://geo.ipify.org/api/v1?apiKey=at_znh78XdM9ThqMrcq2kwxhbRCHXG1m')
      .then(res => res.json())
      .then(ipdata => {
        if(ipdata && ipdata.ip){
          // https://ipapi.co/208.117.244.75/json/
          let dataip = '105.186.84.157'
          console.log("APi ", ipdata)
          fetch(`https://ipapi.co/${ipdata.ip}/json/`)
            .then(res => res.json())
            .then(data => {
              console.log("APi ", data)
              if (data) {
                this.setState({ userLocation: data })

                // Get Country-Code
                let countryCode = ''
                let code1 = data.languages.includes("en")
                let code2 = data.languages.includes("pt")
                if(code1){
                  countryCode = 'en'
                }
                if(code2){
                  countryCode = 'pt'
                }          

                // Get Country-Flug
                let flug = ''
                this.state.defaultLanguage.forEach(e =>{
                  if(e.code === countryCode){
                    flug = e.flug
                  }else{
                    flug = this.state.emoji
                  }
                })

                // Set Language
                if (flug.length > 0 && countryCode.length > 0) {
                  this.setState(state => ({ language: countryCode, emoji: flug }))
                  this.fetchPageDetails(countryCode)
                  this.getResumeDataOnLanguage(countryCode)
                  this.setLanguageEmoji()
                  this.removeSpinner();
                } else {
                  // Default
                  this.setDefaultDetails()
                }
              } else {
                this.setDefaultDetails()
              }
            })
            .catch(err => console.log(err))
        }else{
          this.setDefaultDetails()
        }
      })
      .catch(err => console.log(err))
  }

  // fetchUserLocation() {
  //   // https://ipgeolocation.abstractapi.com/v1?api_key=ccc915dc756646cd8963837c7224e991
  //   // Fetching user IP address and then fetch user current address
  //   // Goto https://geo.ipify.org/docs
  //   fetch('https://geo.ipify.org/api/v1?apiKey=at_znh78XdM9ThqMrcq2kwxhbRCHXG1m')
  //     .then(res => res.json())
  //     .then(data => {
  //       // data = {}
  //       if (data && data.ip) {
  //         // data.ip = '105.186.84.157' // South Africa
  //         // data.ip = '208.117.244.75' // USA
  //         // data.ip = '18.135.80.147'  // UK
  //         // data.ip = '197.149.194.103'  // Guinee Conakry
  //         // Goto https://apilayer.com/
  //         fetch(`http://api.ipstack.com/${data.ip}?access_key=32a57569ee3ac4528bae4cb8717778cc/`,
  //           {
  //             method: 'POST',
  //             mode: 'cors',
  //             mode: 'cors',
  //             headers: {
  //               'Content-Type': 'application/json',
  //               'Access-Control-Allow-Origin': '*',
  //             },
  //             body: JSON.stringify(body)
  //           })
  //           .then(res => res.json())
  //           .then(dataLocation => {
  //             if (dataLocation && dataLocation.location && dataLocation.location.languages.length > 0) {
  //               this.setState({ userLocation: dataLocation })
  //               const locationCd = dataLocation.location.languages.find(item => {
  //                 if (item && item.code && (item.code === 'en' || item.code === 'pt')) return item;
  //                 return false
  //               })

  //               // Set Country-Flug
  //               if (/\p{Emoji}/u.test(dataLocation.location.country_flag_emoji)) {
  //                 this.setState({ emoji: dataLocation.location.country_flag_emoji })
  //               }

  //               // Set Language
  //               if (locationCd && Object.keys(locationCd).length > 0 && locationCd && locationCd.code) {
  //                 this.setState({ language: locationCd.code })
  //                 this.fetchPageDetails(locationCd.code.toUpperCase())
  //                 this.getResumeDataOnLanguage(`resume${locationCd.code.toUpperCase()}`)
  //                 // Save lang and emoji in LocalStorage 
  //                 this.setLanguageEmoji()
  //                 this.removeSpinner();
  //               } else {
  //                 // Default
  //                 this.checkIfContains('def')
  //                 this.setLanguageEmoji()
  //                 this.removeSpinner();
  //               }
  //             }else{
  //               console.log('Error on fetch GEO data...')
  //             }
  //           })
  //           .catch(err => console.log(err))
  //       } else {
  //         try {
  //           // Get the location-language from the navigator window.navigator.language || 
  //           const language = window.navigator.language || window.navigator.systemLanguage || window.navigator.userLanguage || window.navigator.browserLanguage || window.navigator.languages
  //           if (Array.isArray(language) && language.length > 0) {
  //             this.checkIfContains(language[2])
  //           } else if (typeof language === 'string') {
  //             this.checkIfContains('language')
  //           } else {
  //             this.checkIfContains('def')
  //           }
  //         } catch (err) {
  //           console.log(err)
  //         }
  //       }
  //     })
  //     .catch(err => console.log(err))
  // }

  setDefaultDetails(){
    try {
      // Get the location-language from the navigator window.navigator.language || 
      const language = window.navigator.language || window.navigator.systemLanguage || window.navigator.userLanguage || window.navigator.browserLanguage || window.navigator.languages
      if (Array.isArray(language) && language.length > 0) {
        this.checkIfContains(language[2])
      } else if (typeof language === 'string') {
        this.checkIfContains('language')
      } else {
        this.checkIfContains('def')
      }
    } catch (err) {
      console.log(err)
    }
  }

  checkIfContains(langCode) {
    if (langCode.includes('en')) {
      this.setState(state => ({ language: state.defaultLanguage[0].code, emoji: state.defaultLanguage[0].flug }), () => {
        this.fetchPageDetails(this.state.language.toUpperCase())
        this.getResumeDataOnLanguage(this.state.language)
      })
    } else if (langCode.includes('pt')) {
      this.setState(state => ({ language: state.defaultLanguage[1].code, emoji: state.defaultLanguage[1].flug }), () => {
        this.fetchPageDetails(this.state.language.toUpperCase())
        this.getResumeDataOnLanguage(this.state.language)
      })
    } else {
      this.setState(state => ({ language: state.defaultLanguage[0].code, emoji: state.defaultLanguage[0].flug }), () => {
        this.fetchPageDetails('EN')
        this.getResumeDataOnLanguage('EN')
      })
    }
    this.setLanguageEmoji()
    this.removeSpinner();
  }

  getResumeDataOnLanguage(lang) {
    fetch(`/data/resume${lang.toUpperCase()}.json`)
      .then(res => res.json())
      .then(data => this.setState({ resumeData: data }))
      .catch(err => console.log(err))
  }

  fetchPageDetails(lang) {
    fetch(`/data/pageData${lang.toUpperCase()}.json`)
      .then(res => res.json())
      .then(data => this.setState({ pageData: data }))
      .catch(err => console.log(err))
  }

  setLanguageEmoji() {
    localStorage.setItem('currentLocation', this.state.language)
    localStorage.setItem('currentEmoji', this.state.emoji)
  }

  removeSpinner = () => {
    setTimeout(() => {
      document.querySelector('#spinImageHolder').style.display = 'none'
      document.querySelector('#spaBody').setAttribute('style', '')
      document.querySelector('body').setAttribute("style", `overflow: initial !important;`) // Set overflow On
    }, 1000);
  }

  handleLangChange = (lang) => {
    if (this.state.language !== lang) {
      let emoji = localStorage.getItem('currentEmoji')
      if (emoji) {
        this.setState({ language: lang, emoji }, () => {
          this.setLanguageEmoji()
          window.location.reload()
          // console.log('Lang: ',this.state.language)
          // console.log('Emoji: ', this.state.emoji)
        })
      }
    }
  }

  render() {
    return (
      <div className="App">
        <div id="spinImageHolder" >
          <img id="spinImage" src="../images/spin.gif" alt="Spinnig" />
        </div>
        <div id="myFaceAtBottom" className="text-center">
          <div style={{ fontSize: "45px", marginBottom: "5px" }}> <span role="img" aria-labelledby="emoji">🧔🏾</span> </div>
          <div style={{ fontSize: "11px", marginBottom: "2px", fontWeight: "500" }} >BlackBeard</div>
        </div>

        <Header data={this.state.resumeData.main} menus={this.state.pageData.menus} language={this.state.language} emoji={this.state.emoji} parentLangChange={this.handleLangChange} />
        <About data={this.state.resumeData.main} about={this.state.pageData.about} />
        <Resume data={this.state.resumeData.resume} resume={this.state.pageData.resume} />
        <DevStack data={this.state.resumeData.favoritetech} techs={this.state.pageData.techs} />
        <Portfolio data={this.state.resumeData.portfolio} work={this.state.pageData.work} />
        <Testimonials data={this.state.resumeData.testimonials} testimonial={this.state.pageData.testimonial} />
        <Contact data={this.state.resumeData.main} contact={this.state.pageData.contact} lang={this.state.language} />
        <Footer data={this.state.resumeData.main} footer={this.state.pageData.footer} />
      </div>
    );
  }
}

export default App;
