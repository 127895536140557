import React, { useState } from 'react';

const Contact = ({ data, contact, lang }) => {
   const [name, setName] = useState('');
   const [isName, setIsName] = useState(true);
   const [subject, setSubject] = useState('');
   const [isSubject, setIsSubject] = useState(true);
   const [email, setEmail] = useState('');
   const [isEmailValid, setEmailValid] = useState(true);
   const [message, setMessage] = useState('');
   const [isMessage, setIsMessage] = useState(true);
   const [errorContentMsg, setErrorContentMsg] = useState('');
   const [successContentMgs, setSuccessContentMgs] = useState('');
   const [errorMsg, setErrorMsg] = useState(false);
   const [successMgs, setSuccessMgs] = useState(false);
   const [isEnabled, setIsEnabled] = useState(false);

   const setLangMsg = (data = null) => {
      if(lang === 'en'){
         if(data != null){
            setErrorContentMsg(data.error)
            setSuccessContentMgs(data.success)
         }else{
            setErrorContentMsg('Error! Your message was not sent. Sorry!')
            setSuccessContentMgs('Success! Your message was sent. Thank you.')
         }
      }else{
         if(data != null){
            setErrorContentMsg(data.error)
            setSuccessContentMgs(data.success)
         }else{
            setErrorContentMsg('Erro! Sua mensagem não foi enviada. Perdão!')
            setSuccessContentMgs('Successo! Sua mensagem foi enviada. Obrigado.')
         }
      }
   }
   const resetFields = () => {
      setName("");
      setSubject("");
      setEmail("");
      setMessage("");
   }
   const EmailValidatation = (useremail)=> {
      setEmail(useremail)
      const emailregax = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if(emailregax.test(useremail)){
         setEmailValid(true)
      }else{
         setEmailValid(false)
      }
   }
   const checkName = (name) => {
      setName(name)
      if(name.length > 1){
         setIsName(true)
      }else{
         setIsName(false)
      }
   }
   const checkSubject = (subject) => {
      setSubject(subject)
      if(subject.length > 3){
         setIsSubject(true)
      }else{
         setIsSubject(false)
      }
   }
   const checkMessage = (message) => {
      setMessage(message)
      if(message.length > 5){
         setIsMessage(true)
      }else{
         setIsMessage(false)
      }
   }
   const handleClick = (e) => {
      e.preventDefault()
      setLangMsg()
      setIsEnabled(true)

      if(isEmailValid && isName && isSubject && isMessage &&
          email.length > 0 && name.length > 0 && subject.length > 0 && message.length > 0){
         window.Email.send({
            SecureToken: 'dd7fafe2-f76e-42c2-a07b-c1d99eb06a20',
            // SecureToken: '01828967-0c14-41b4-b47f-e5b837d3cea8',
            // 01828967-0c14-41b4-b47f-e5b837d3cea8
            tls: { ciphers: 'SSLv3' },
            To: 'moisesnt2@icloud.com',
            // Host : "smtp.gmail.com",
            // Username : "managebbapp@gmail.com",
            // Password : "managebbclient2@",

            From: email,
            Subject: subject,
            Body: message
         })
         .then(resp => {
   
            if(resp === "OK") {
               // Confirm sent email
               setSuccessMgs(true)
               resetFields();
            }else{
               // Show email error
               setErrorMsg(true)
            }
            setTimeout(() => {
               setErrorMsg(false)
               setSuccessMgs(false)
               setIsEnabled(false)
            }, 6000);
         })
      }else{
         lang === 'en' ? 
         setLangMsg({error: "Please, Provide the correct details.", success: ""}) : 
         setLangMsg({error: "Por favor, forneça os detalhes corretos.", success: ""})
         setErrorMsg(true)
         setTimeout(() => {
            setErrorMsg(false)
            setSuccessMgs(false)
            setIsEnabled(false)
         }, 6000);
      }
   }

   return (
      <section id="contact">
         <div className="row section-head">
            <div className="two columns header-col">
               <i className="fa fa-check" />
               <h1><span>{contact?.title}</span></h1>
            </div>

            <div className="ten columns leadHolder">
               <h1>{contact?.title}</h1>
               <p className="lead">{contact?.subtitle}</p>
            </div>
         </div>

         <div className="row">
            <div className="eight columns">
               <form id="contactForm" name="contactForm">
                  <fieldset>
                     <div>
                        <label htmlFor="contactName">{contact?.form.name}<span className="required">*</span></label>
                        <input value={name} style={{ border: isName ? '' : '#d9534f 2px solid' }} type="text" size="35" id="contactName" name="contactName" onChange={e => checkName(e.target.value)} />
                        
                     </div>

                     <div>
                        <label htmlFor="contactEmail">{contact?.form.email} <span className="required">*</span></label>
                        <input value={email} style={{ border: isEmailValid ? '' : '#d9534f 2px solid' }} type="text" size="35" id="contactEmail" name="contactEmail" onChange={e => EmailValidatation(e.target.value)} />
                     </div>

                     <div>
                        <label htmlFor="contactSubject">{contact?.form.subject} <span className="required">*</span></label>
                        <input value={subject} style={{ border: isSubject ? '' : '#d9534f 2px solid' }} type="text" size="35" id="contactSubject" name="contactSubject" onChange={e => checkSubject(e.target.value)} />
                     </div>

                     <div>
                        <label htmlFor="contactMessage">{contact?.form.message} <span className="required">*</span></label>
                        <textarea value={message} onChange={e => checkMessage(e.target.value)} style={{ border: isMessage ? '' : '#d9534f 2px solid', resize: "none" }} cols="50" rows="10" id="contactMessage" name="contactMessage" ></textarea>
                     </div>

                     {successMgs || errorMsg ? (
                        <div>
                           {/* <label htmlFor="contactName" style={{ visibility: 'hidden' }}>Name <span className="required">*</span></label> */}
                           {/* <input value={name} type="text" size="35" id="contactName" name="contactName" onChange={e => setName(e.target.value)} /> */}
                           <div id="message-warning" style={{ display: errorMsg ? 'inline-block' : 'none' }}>
                              <i className="fa fa-check" />
                              <span>{errorContentMsg}</span>
                           </div>
                           <div id="message-success" style={{ display: successMgs ? 'inline-block' : 'none' }}>
                              <i className="fa fa-check" />
                              <span>{successContentMgs}</span>
                           </div>
                           <div className="clearfix"></div>
                        </div>
                     ) : ('')}


                     <div className="btnSubmitHolder">
                        <button type='submit' onClick={handleClick} disabled={isEnabled} className="submit">
                           {contact?.form.sendemail} 
                           <i className="far fa-paper-plane"></i>
                        </button>
                     </div>
                  </fieldset>
               </form>
            </div>


            <aside className="four columns footer-widgets">
               <div className="widget widget_contact">
                  <h4>{contact?.address}</h4>
                  <p className="address">
                     {data?.fullname}<br />
                     {data?.email}<br />
                     {data?.address.street} <br />
                     {data?.address.city} {data?.address.state} {data?.address.zip}<br />
                     <span>{data?.phone}</span>
                  </p>
               </div>

               <div className="widget widget_tweets">
                  <i className="fas fa-quote-left"></i>
                  <h4>{contact?.quotes?.mind}</h4>
                  <i className="fas fa-quote-right"></i>
               </div>
            </aside>
         </div>
      </section>
   );
}

export default Contact;
